import React from 'react'
import Layout from '../components/layout'

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

const NotFoundPage = ({ classes }) => (
  <Layout
    className={classes.root}
    pagedata={{
      title: "Page Not Found",
      description: "You just hit a route that doesn&#39;t exist... the sadness."
    }}
    />
)

export default withStyles(styles)(NotFoundPage);
